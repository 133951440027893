import {HEADER_HIDE, HEADER_SHOW} from "../header/constants";

const hideHeader = () => {
  return {
    type: HEADER_HIDE
  }
}

const showHeader = () => {
  return {
    type: HEADER_SHOW
  }
}

export {
  hideHeader,
  showHeader
}
