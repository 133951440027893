import {
  VOTE_CREATE_SETUP_REQUESTING,
  VOTE_SETUP_REQUESTING,
  VOTE_UPDATE_SETUP_REQUESTING,
  VOTING_FETCH_EVALUATIONS_REQUESTING, VOTING_SET_CURRENT_EVALUATION
} from "./constants";

export const getSetup = (id) => {
  return {
    type: VOTE_SETUP_REQUESTING,
    id
  }
};

export const doUpdateSetup = (id, data) => {
  return {
    type: VOTE_UPDATE_SETUP_REQUESTING,
    updateData: data,
    id
  }
};

export const doCreateSetup = (data) => {
  return {
    type: VOTE_CREATE_SETUP_REQUESTING,
    data
  }
}

export const getVotings = () => {
  return {
    type: VOTING_FETCH_EVALUATIONS_REQUESTING
  }
}

export const setCurrentEvaluation = (evaluation) => {
  return {
    type: VOTING_SET_CURRENT_EVALUATION,
    evaluation
  }
}

