import styled from 'styled-components';

export const FlexGap = styled.div`
  --gap: ${props => props.gap ? props.gap : "0.5rem"};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: calc(100% + var(--gap));
  margin-bottom: calc(-1 * var(--gap));
  margin-left: calc(-1 * var(--gap));

  >* {
    display: flex;
    flex-direction: column;
    width: calc(${props => props.count ? 100/props.count : 100}% - var(--gap));
    margin-bottom: var(--gap);
    margin-left: var(--gap);
  }

  @media only screen and (min-width: 0px) and (max-width: 767px) {
    >* {
      width: 100%;
      flex: auto;
    }
  }
`
