export const API_BASE_URL = window.API_BASE_URL ? window.API_BASE_URL : window.location.hostname.includes("localhost") ? "http://localhost:3020/api/v1" : 'https://' + window.location.hostname + '/api/v1';

// Re export all constants in components so we can import this file to
// get other components constants
export * from './components/user/constants';
export * from './components/auth/constants';

export const AppRoles = {
  ADMIN: 'ADMIN',
  THEME: 'THEME',
  PRESENTER: 'PRESENTER',
  MEMBER: 'MEMBER'
}
