import {API_BASE_URL} from "../../constants";
import React, {useState} from "react";
import Lightbox from "react-image-lightbox";

export const getImageUrl = (image) => {
  return API_BASE_URL + "/image/" + image.uuid;
}

export const ImageBox = ({path}) => {
  const [lbImages, setLbImages] = useState(null);
  const [lbImagesIdx, setLbImagesIdx] = useState(0);

  return (
    <>
    <img src={path}
         alt={"Type"}
         className="mt-2 shadow img-fluid rounded mx-auto d-block"
         onClick={() => {setLbImagesIdx(0); setLbImages([path])}}
    />
      {lbImages!==null && (
        <Lightbox
          mainSrc={lbImages[lbImagesIdx]}
          onCloseRequest={() => setLbImages(null)}
        />
      )}
    </>
  )
}
