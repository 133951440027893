import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {useRouteMatch, withRouter} from "react-router";

import {makeStyles} from '@material-ui/core/styles';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {Container} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Grid from "@material-ui/core/Grid";
import './index.scss';
import {Fetch} from "../../common/lib/api-fetch";
import {showErrorText, showInfo} from "../../common/lib/show-toast";
import {API_BASE_URL} from "../../constants";
import {ButtonBlack} from "../../common/components/color-button";
import Col from "react-bootstrap/Col";
import TextField from "@material-ui/core/TextField";

const _ = require('lodash');

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    minHeight: '100vh'
  },
  infoContainer: {
    flexGrow: 1
  }
}));

const valueDef = {
  username: {
    label: 'Brukernavn',
    type: 'text',
    value: '',
    position: 1,
    required: 'Du må fylle ut brukernavn',
    helperText: 'Et unikt brukernavn på tjenesten',
  },
  nick: {
    label: "Kallenavn",
    type: 'text',
    value: '',
    position: 2,
    helperText: 'Dette vil bli brukt i stedet for brukernavnet når det vises'
  },
  firstName: {
    label: 'Fornavn',
    type: 'text',
    value: '',
    position: 3,
    required: 'Du må fylle ut fornavn',
    regex: '\\w+'
  },
  lastName: {
    label: 'Etternavn',
    type: 'text',
    value: '',
    position: 4,
    required: 'Du må fylle ut etternavn',
    regex: '\\w+'
  },
  email: {
    label: 'Epost',
    type: 'email',
    value: '',
    position: 5,
    required: 'Du må fylle inn epost'
  },

}

const UserInfo = ({pathUser, updateUser}) => {
  const classes = useStyles();
  const [values, setValues] = useState({});
  const [username, setUsername] = useState('');
  const [nick, setNick] = useState('');
  const [email, setEmail] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [legalUsername, setLegalUsername] = useState(true);
  const [legalNick, setLegalNick] = useState(true);
  const [legalEmail, setLegalEmail] = useState(true);
  const [orgValues, setOrgValues] = useState({});

  useEffect(() => {
    if (!_.isEmpty(pathUser)) {
      setUsername(pathUser.username);
      setNick(pathUser.nick === null ? '' : pathUser.nick);
      setEmail(pathUser.email);
      setFirstname(pathUser.firstName);
      setLastname(pathUser.lastName);
      let newOrgValues = _.cloneDeep(pathUser);
      if (newOrgValues.nick === null)
        newOrgValues.nick = '';
      setOrgValues(newOrgValues);
    }
  }, [pathUser]);

  const checkUsername = (username) => {
    setUsername(username);
    if (username === orgValues.username) {
      setLegalUsername(true);
    } else {
      Fetch(API_BASE_URL + "/users/availableUsername", "POST", {username: username}).then(result => {
        setLegalUsername(result.data);
      }).catch(err => {
        setLegalUsername(false);
      })
    }
  }

  const checkNick = (nick) => {
    setNick(nick);
    if (nick === orgValues.nick) {
      setLegalNick(true);
    } else {
      Fetch(API_BASE_URL + '/users/availableNick', "POST", {nick: nick}).then(result => {
        setLegalNick(result.data);
      }).catch(err => {
        setLegalNick(false);
      })
    }
  }

  const checkEmail = (email) => {
    setEmail(email);
    if (email === orgValues.email) {
      setLegalEmail(true);
    } else {
      Fetch(API_BASE_URL + "/users/availableEmail", "POST", {email: email}).then(result => {
        setLegalEmail(result.data);
      }).catch(err => {
        setLegalEmail(false);
      })
    }
  }

  const isLegal = () => {
    return legalUsername && legalEmail && legalNick &&
      username.length !== 0 &&
      email.length !== 0 &&
      firstname.length !== 0 &&
      lastname.length !== 0
  }

  const isChanged = () => {
    return username !== orgValues.username ||
      nick !== orgValues.nick ||
      firstname !== orgValues.firstName ||
      lastname !== orgValues.lastName ||
      email !== orgValues.email;
  }

  const saveUser = () => {
    let data = {
      id: pathUser.id,
      username: username,
      nick: nick === '' ? null : nick,
      firstName: firstname,
      lastName: lastname,
      email: email
    };
    Fetch(API_BASE_URL + "/users/" + pathUser.id + "/profile", "PUT", data).then(result => {
      showInfo("Brukeren er oppdatert");
      updateUser(true);
    }).catch(err => {
      console.log(err);
      showErrorText("Noe gikk galt ved lagring av bruker");
    })
  }

  return (
    <>
      <div className={classes.infoContainer}>
        <Grid
          container
          spacing={3}
          direction="column"
          justify="center"
          alignItems="center"
          className="mt-4"

        >

          <Grid item xs={12}>
            <div className={"title"}>INFORMASJON</div>
          </Grid>
        </Grid>
        {!_.isEmpty(pathUser) &&
        <>
          <Grid container justify={"center"}>
            <Grid item xs={12} md={8}>
              <Row>
                <Col>
                  <TextField
                    key={'username'}
                    id={'username'}
                    label={'Brukernavn'}
                    placeholder={'Brukernavn'}
                    type={'text'}
                    value={username}
                    onChange={(event) => checkUsername(event.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin="normal"
                    variant="outlined"
                    helperText={legalUsername ? 'Et unikt brukernavn' : 'Brukernavnet finnes fra før'}
                    required={true}
                    error={!legalUsername || username.length === 0}
                    fullWidth={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextField
                    key={'nick'}
                    id={'nick'}
                    label={'Kallenavn'}
                    placeholder={'Kallenavn'}
                    type={'text'}
                    value={nick}
                    onChange={(event) => checkNick(event.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin="normal"
                    variant="outlined"
                    helperText={legalNick ? 'Et unikt kallenavn' : 'Kallenavnet finnes fra før'}
                    required={true}
                    error={!legalNick}
                    fullWidth={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextField
                    key={'firstname'}
                    id={'firstname'}
                    label={'Fornavn'}
                    placeholder={'Fornavn'}
                    type={'text'}
                    value={firstname}
                    onChange={(event) => setFirstname(event.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin="normal"
                    variant="outlined"
                    helperText={firstname.length === 0 ? "Du må fylle ut fornavn" : ''}
                    required={true}
                    error={firstname.length === 0}
                    fullWidth={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextField
                    key={'lastname'}
                    id={'lastname'}
                    label={'Etternavn'}
                    placeholder={'Etternavn'}
                    type={'text'}
                    value={lastname}
                    onChange={(event) => setLastname(event.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin="normal"
                    variant="outlined"
                    helperText={lastname.length === 0 ? "Du må fylle ut etternavn" : ''}
                    required={true}
                    error={lastname.length === 0}
                    fullWidth={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextField
                    key={'email'}
                    id={'email'}
                    label={'E-post'}
                    placeholder={'E-post'}
                    type={'text'}
                    value={email}
                    onChange={(event) => checkEmail(event.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin="normal"
                    variant="outlined"
                    helperText={legalEmail ? 'En unikt e-post adresse' : 'E-post adressen finnes fra før'}
                    required={true}
                    error={!legalEmail || email.length === 0}
                    fullWidth={true}
                  />
                </Col>
              </Row>

              <div className="ml-auto">
                <ButtonBlack variant="contained" onClick={() => saveUser()}
                             disabled={!isLegal() || !isChanged()}>Lagre</ButtonBlack>
              </div>
            </Grid>
          </Grid>
        </>
        }
      </div>
    </>
  )
}

const UserHome = () => {
  const classes = useStyles();
  const [tab, setTab] = useState(0);
  const [updateUser, setUpdateUser] = useState(false);
  let path = useRouteMatch({
    path: "/user/:id",
    sensitive: true
  });
  const [pathUserId, setPathUserId] = useState(0);
  const [pathUser, setPathUser] = useState({});

  useEffect(() => {
    if (pathUserId !== 0 || updateUser) {
      Fetch(API_BASE_URL + "/users/" + pathUserId + "/profile", "GET").then(result => {
        setPathUser(result.data);
      }).catch(error => {
        showErrorText("Det gikk noe galt ved henting av bruker");
      })
    } else {
      setPathUser({});
    }
    setUpdateUser(false);
  }, [pathUserId, updateUser]);

  useEffect(() => {
    setPathUserId(path ? path.params.id : 0);
  }, [path])


  return (
    <>
      <Container className={classes.root}>
        <Row className="justify-content-center user-profile" md={8}>
          <Container>
            <Tabs
              value={tab}
              onChange={(e, v) => setTab(v)}
              indicatorColor="primary"
              centered
            >
              <Tab label="Bruker"/>
            </Tabs>
            <div hidden={tab !== 0}>
              <UserInfo pathUser={pathUser} updateUser={setUpdateUser}/>
            </div>
          </Container>
        </Row>
      </Container>
    </>
  );
}

const mapStateToProps = state => ({});

const connected = connect(mapStateToProps, {})(UserHome);

export default withRouter(connected);
