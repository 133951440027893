import {KeyboardDatePicker, KeyboardDateTimePicker} from "@material-ui/pickers";
import React, {Fragment} from "react";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import JpgDropzone from "../../common/components/jpg-dropzone";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import _ from "lodash";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import 'react-quill/dist/quill.snow.css';
import {API_BASE_URL} from "../../constants";
import Box from "@material-ui/core/Box";
import ReactQuill, {Quill} from 'react-quill';
import ImageResize from 'quill-image-resize-module-react';  // import as default
import {EvaluationMultiSelect} from './EvaluationMultiSelect';
Quill.register('modules/imageResize', ImageResize);

export const setFetchData = ({values, setValues, data}) => {
  let newValues = _.cloneDeep(values);
  setFetchDataLazy({values: newValues, data});
  setValues(newValues);
};

export const setFetchDataLazy = ({values, data}) => {
  for (let key of Object.keys(values)) {
    if (key in data) {
      values[key].value = data[key];
    }
  }
}

export const hideFieldsLazy = ({values, fields}) => {
  for (let key of fields) {
    values[key].hidden = true;
  }
}

export const addDataDefs = ({values, keys, defs}) => {
  for (let key of keys) {
    if (key !== "")
      values[key] = _.cloneDeep(defs[key]);
  }
}

// This will add all keys in the extra object
export const addDataExtra = ({values, extra}) => {
  for (let key of Object.keys(extra)) {
    values[key] = _.cloneDeep(extra[key]);
  }
}

export const FieldsToRows = ({dataCol = 12, values, classes, infoForm, data}) => {
  return (
    informationKeysInOrder(values).map(key => {
      if (values[key].hidden) return <Fragment key={key}></Fragment>
      return <Row id={"row" + key} key={key}>
        <Col md={{
          span: 'indent' in values[key] ? dataCol - values[key].indent : dataCol,
          offset: 'indent' in values[key] ? values[key].indent : 0
        }}>
          {createInformationField({
            values,
            key,
            classes,
            isError: infoForm.isError,
            handleChecked: infoForm.handleChecked,
            handleEvent: infoForm.handleEvent,
            handleChange: infoForm.handleChange,
            changeValue: infoForm.changeValue,
            data
          })}
        </Col>
      </Row>
    })
  );
}

export const createInformationField = ({values, key, classes, isError, handleChecked, handleEvent, handleChange, changeValue, form, data}) => {
  if (!(key in values)) return <Fragment key={key}/>
  const field = values[key];

  const setRegex = (regex) => {
    if (!('regex' in values[key])) {
      values[key].regex = regex;
    }
  };
  if (form) {
    isError = form.isError;
    handleChecked = form.handleChecked;
    handleEvent = form.handleEvent;
    handleChange = form.handleChange;
    changeValue = form.changeValue;
  }

  switch (field.type) {
    case 'boolean':
      return createBooleanField({key, values, classes, handleChecked});
    case 'text':
      return createTextField({key, values, classes, isError, handleEvent});
    case 'number':
      setRegex('\\d+');
      return createTextField({key, values, classes, isError, handleEvent});
    case 'date':
      return createDateField({key, values, classes, isError, handleChange});
    case 'datetime':
      return createDateTimeField({key, values, classes, isError, handleChange});
    case 'image':
      return createImageField({key, values, classes, handleChange});
    case 'select':
      return createSelectField({key, values, classes, handleEvent, isError});
    case 'votingselect':
      return createEvaluationMultiSelectField({key, values, classes, changeValue, isError, data});
    case 'email':
      setRegex('^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$');
      return createTextField({key, values, classes, isError, handleEvent});
    case 'mobile':
      setRegex('^([0|\\+[0-9]{1,5})?[-0-9 ]{8,}$');
      return createTextField({key, values, classes, isError, handleEvent});
    case 'editor':
      return createEditorField({key, values, classes, handleChange});
    default:
      return <></>
  }
}

export const informationKeysInOrder = (values) => {
  const keys = _.sortBy(Object.keys(values), [(row) => values[row].position]);
  return keys;
}

const helperText = ({key, values, isError}) => {
  const field = values[key];
  return isError(key) && 'required' in field ? field.required : isError(key) && 'errorText' in field ? field.errorText : 'helperText' in field ? field.helperText : '';
}


const createDateField = ({key, values, classes, isError, handleChange}) => {
  const field = values[key];

  return (
    <KeyboardDatePicker
      key={key}
      required
      margin="normal"
      id={key}
      label={'label' in field ? field.label : key}
      format={'format' in field ? field.format : "dd.MM.yyyy"}
      value={values[key].value || Date.now()}
      onChange={handleChange(key)}
      ampm={'ampm' in field ? field.ampm : false}
      className={classes.dateField}
      helperText={helperText({key, values, isError})}
    />
  )
}

const createDateTimeField = ({key, values, classes, isError, handleChange}) => {
  const field = values[key];

  return (
    <KeyboardDateTimePicker
      key={key}
      required
      margin="normal"
      id={key}
      label={'label' in field ? field.label : key}
      format={'format' in field ? field.format : "dd.MM.yyyy HH:mm:ss"}
      value={values[key].value || Date.now()}
      onChange={handleChange(key)}
      ampm={'ampm' in field ? field.ampm : false}
      className={classes.dateField}
      helperText={helperText({key, values, isError})}
    />
  )
}

const createTextField = ({key, values, classes, isError, handleEvent}) => {
  const field = values[key];
  return (
    <TextField
      key={key}
      id={key}
      name={'name' in field ? field.name : key}
      label={'label' in field ? field.label : key}
      placeholder={'placeholder' in field ? field.placeholder : 'label' in field ? field.label : ''}
      type={'type' in field ? field.type : 'text'}
      value={values[key].value}
      onChange={handleEvent}
      InputLabelProps={{
        shrink: true,
      }}
      margin="normal"
      variant="outlined"
      helperText={helperText({key, values, isError})}
      className={classes.textField}
      required={'required' in field ? true : false}
      error={isError(key)}
      multiline={'lines' in field}
      rows={'lines' in field ? field.lines : 0}
      fullWidth={true}
    />
  )
}

const createBooleanField = ({key, values, classes, handleChecked}) => {
  const field = values[key];
  return (
    <FormControlLabel
      key={key}
      classes={{
        label: classes.label,
      }}
      control={
        <Checkbox
          checked={field.value}
          onChange={handleChecked(key)}
          value={field.value}
          color="primary"
        />}
      label={field.label}
    />
  )
}

const createImageField = ({key, values, handleChange}) => {
  const field = values[key];
  return (
    <>
      {field.value && field.value !== "" &&
      <Box display="flex" alignItems="center" justifyContent="center">
        <img src={API_BASE_URL + "/image/" + field.value} style={{height: '100px'}} className="img-fluid mb-1"/>
      </Box>
      }
      <JpgDropzone className="d-block w-100" count={1} onUploaded={handleChange(key)}/>
    </>
  )
}

const createSelectField = ({key, values, classes, handleEvent, isError}) => {
  const field = values[key];
  return (
    <FormControl className={classes.formControl} key={key}>
      <InputLabel id={"label" + key}>{field.label}</InputLabel>
      <Select
        labelId={"label" + key}
        id={"select" + key}
        name={key}
        value={field.value}
        onChange={handleEvent}
        error={isError(key)}
      >
        {field.possibleValues.map(v => {
          return <MenuItem key={typeof v === 'string' ? v : v.key} value={typeof v === 'string' ? v : v.key}>
            {typeof v === 'string' ? v : v.value}
          </MenuItem>
        })}
      </Select>
      <FormHelperText>{'helperText' in field ? field.helperText : ''}</FormHelperText>
    </FormControl>
  )
}

const createEvaluationMultiSelectField = ({key, values, classes, changeValue, isError, data}) => {
  const field = values[key];

  console.log("Setting up key", key);
  return (
    <EvaluationMultiSelect allVotings={data.votings} handleChange={changeValue} fieldKey={key} value={field.value}/>
  )
}

/*
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
const EditorModules = {
  imageResize: {
    handleStyles: {
      backgroundColor: 'black',
      border: 'none',
      color: 'white',
    },
    modules: ['Resize', 'DisplaySize', 'Toolbar'],
  },
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],

    [{'header': 1}, {'header': 2}],               // custom button values
    [{'list': 'ordered'}, {'list': 'bullet'}],
    [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
    [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
    [{'direction': 'rtl'}],                         // text direction
    ['link', 'image', 'video', 'formula'],
    [{'size': ['small', false, 'large', 'huge']}],  // custom dropdown
    [{'header': [1, 2, 3, 4, 5, 6, false]}],

    [{'color': []}, {'background': []}],          // dropdown with defaults from theme
    [{'font': []}],
    [{'align': []}],

    ['clean']                                         // remove formatting button
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  }
}
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
const EditorFormats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image', 'video', 'align'
]

const createEditorField = ({key, values, classes, handleChange}) => {
  const field = values[key];
  return (
    <div className={"my-2"} key={key}>
      {'menu' in field && !field.menu ?
        <ReactQuill
          value={field.value}
          readOnly={true}
          theme={"bubble"}
        />
        :
        <>
          <InputLabel id={"label" + key}>{field.label}</InputLabel>
          <ReactQuill value={field.value}
                      onChange={handleChange(key)}
                      modules={_.cloneDeep(EditorModules)}
                      formats={_.cloneDeep(EditorFormats)}
                      readOnly={'readonly' in field ? field.readonly : false}
          />
        </>
      }
    </div>
  )
}
