import {
  VOTE_CREATE_SETUP_ERROR,
  VOTE_REMOVE_ERROR,
  VOTE_SETUP_ERROR,
  VOTE_SETUP_SUCCESS,
  VOTE_UPDATE_SETUP_ERROR,
  VOTING_FETCH_EVALUATIONS_REQUESTING,
  VOTING_FETCH_EVALUATIONS_SUCCESS,
  VOTING_SET_CURRENT_EVALUATION,
} from "./constants";

const initialState = {
  currentEvaluation: {},
  evaluations: [],
  setup: {},
  error: {}
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case VOTE_REMOVE_ERROR: {
      return {
        ...state,
        error: {}
      }
    }
    case VOTE_SETUP_SUCCESS:
      return {
        ...state,
        setup: action.data
      };
    case VOTE_SETUP_ERROR:
      return {
        ...state,
        error: {
          msg: 'Feil på henting av konkurranse'
        }
      };
    case VOTE_UPDATE_SETUP_ERROR:
      return {
        ...state,
        error: {
          msg: 'Feil på lagring av konkurranse'
        }
      };
    case VOTE_CREATE_SETUP_ERROR:
      return {
        ...state,
        error: {
          msg: 'Feil på laging av konkurranse'
        }
      };
    case VOTING_FETCH_EVALUATIONS_SUCCESS:
      return {
        ...state,
        evaluations: action.data
      };
    case VOTING_SET_CURRENT_EVALUATION:
      return {
        ...state,
        currentEvaluation: {...action.evaluation}
      };
    default:
      return {...state};
  }
}

export default reducer;
