export const VOTE_REMOVE_ERROR = 'VOTE_REMOVE_ERROR';

export const VOTE_SETUP_REQUESTING = 'VOTE_SETUP_REQUESTING';
export const VOTE_SETUP_SUCCESS = 'VOTE_SETUP_SUCCESS';
export const VOTE_SETUP_ERROR = 'VOTE_SETUP_ERROR';

export const VOTE_UPDATE_SETUP_REQUESTING = 'VOTE_UPDATE_SETUP_REQUESTING';
export const VOTE_UPDATE_SETUP_SUCCESS = 'VOTE_UPDATE_SETUP_SUCCESS';
export const VOTE_UPDATE_SETUP_ERROR = 'VOTE_UPDATE_SETUP_ERROR';

export const VOTE_CREATE_SETUP_REQUESTING = 'VOTE_CREATE_SETUP_REQUESTING';
export const VOTE_CREATE_SETUP_SUCCESS = 'VOTE_CREATE_SETUP_SUCCESS';
export const VOTE_CREATE_SETUP_ERROR = 'VOTE_CREATE_SETUP_ERROR';

export const VOTING_FETCH_EVALUATION_REQUESTING = 'VOTING_FETCH_EVALUATION_REQUESTING';
export const VOTING_FETCH_EVALUATION_SUCCESS = 'VOTING_FETCH_EVALUATION_SUCCESS';
export const VOTING_FETCH_EVALUATION_ERROR = 'VOTING_FETCH_EVALUATION_ERROR';

export const VOTING_FETCH_EVALUATIONS_REQUESTING = 'VOTING_FETCH_EVALUATIONS_REQUESTING';
export const VOTING_FETCH_EVALUATIONS_SUCCESS = 'VOTING_FETCH_EVALUATIONS_SUCCESS';
export const VOTING_FETCH_EVALUATIONS_ERROR = 'VOTING_FETCH_EVALUATIONS_ERROR';

export const VOTING_SET_CURRENT_EVALUATION = 'VOTING_SET_CURRENT_EVALUATION';
