import React, {useEffect, useState} from 'react';

import makeAnimated from 'react-select/animated';
import CreatableSelect from "react-select/creatable/dist/react-select.esm";

const animatedComponents = makeAnimated();

export const EvaluationMultiSelect = ({allVotings, handleChange, fieldKey, ...props}) => {
  const [defaultOptions, setDefaultOptions] = useState([]);

  useEffect(() => {
    setDefaultOptions(allVotings.map(voting => {
      return {value: voting.id, label: voting.title}
    }));
  }, [allVotings]);

  const doChange = (v) => {
    handleChange(fieldKey, v);
  }

  return (
    <CreatableSelect
      closeMenuOnSelect={false}
      components={animatedComponents}
      isMulti
      options={defaultOptions}
      styles={{
        menu: provided => ({...provided, zIndex: 1052})
      }}
      onChange={(v) => handleChange(fieldKey, v)}
      {...props}
    />
  );
}
