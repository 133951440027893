import {
  VOTING_FETCH_EVALUATIONS_ERROR,
  VOTING_FETCH_EVALUATIONS_REQUESTING,
  VOTING_FETCH_EVALUATIONS_SUCCESS
} from "./constants";
import {Fetch} from "../../common/lib/api-fetch";
import {API_BASE_URL} from "../../constants";
import {all, put, takeEvery, call} from "@redux-saga/core/effects";

function apiFetchVotings() {
  return Fetch(API_BASE_URL + "/voting/all", 'GET', {});
}


function* votingsGetWatcher() {
  try {
    const result = yield call(apiFetchVotings);
    yield put({type: VOTING_FETCH_EVALUATIONS_SUCCESS, data: result.data});
  } catch(error) {
    yield put({type: VOTING_FETCH_EVALUATIONS_ERROR, error});
  }
}

function* watchAll() {
  yield all([
    takeEvery(VOTING_FETCH_EVALUATIONS_REQUESTING, votingsGetWatcher),
  ]);
}

export default function* IndexSaga() {
  yield all([
    watchAll(),
  ])
}
