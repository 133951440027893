import {combineReducers} from 'redux';
import user from './components/user/reducer';
import auth from './components/auth/reducer'

import admin from './components/admin/reducer';
import header from './components/header/reducer';
import voting from './components/voting/reducer';

import {connectRouter} from 'connected-react-router';

export default (history) => combineReducers({
  router: connectRouter(history),
  user: user,
  auth: auth,
  admin: admin,
  header: header,
  voting: voting
})
