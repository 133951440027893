import {connect} from "react-redux";
import {withRouter} from "react-router";
import React, {Fragment} from "react";
import VotingDashboard from "../voting/VotingDashboard";

const Dashboard = ({}) => {
  return (
    <Fragment>
      <VotingDashboard />
    </Fragment>
  )
};

const mapStateToProps = state => ({});

const connected = connect(mapStateToProps, {})(Dashboard);

export default withRouter(connected);
