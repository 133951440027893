import {connect} from "react-redux";
import {withRouter} from "react-router";
import React, {useEffect, useState} from "react";

import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import './index.scss';
import {Fetch} from "../../common/lib/api-fetch";
import {API_BASE_URL} from "../../constants";
import {FieldsToRows, setFetchDataLazy} from "../../modules/information/infoFields";
import useInfoForm from "../../modules/forms/useInfoForm";
import {makeStyles} from "@material-ui/core";
import {ButtonBlack} from "../../common/components/color-button";
import {showError, showErrorText, showInfo} from "../../common/lib/show-toast";

const _ = require('lodash');

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    minHeight: '100vh'
  },
  textField: {
    width: '100%'
  },
  dateField: {},
  label: {
    fontSize: '0.75rem',
    color: 'rgba(0, 0, 0, 0.54)'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const valueDef = {
  categoryId: {
    label: 'Kategori',
    type: 'select',
    possibleValues: [{
      key: 1,
      value: 'Årskonkurranse'
    }, {
      key: 3,
      value: 'Opplasting'
    }],
    position: 0,
    value: 1,
    required: 'Du må velge hvilken kategori konkurranse du ønsker'
  },
  typeId: {
    label: 'Type',
    type: 'select',
    possibleValues: [
      {
        key: 1,
        value: 'Farger'
      },
      {
        key: 2,
        value: 'Sort/Hvitt'
      },
      {
        key: 3,
        value: 'Print'
      },
      {
        key: 5,
        value: 'Alle'
      },
      {
        key: 4,
        value: 'Best av'
      },
    ],
    position: 0.5,
    value: 1,
    required: 'Du må velge hvilken type bildene skal være'
  },
  title: {
    label: 'Tittel',
    type: 'text',
    value: '',
    position: 1,
    required: 'Du må fylle inn tittel',
    helperText: 'Tittelen på konkurransen'
  },
  group_name: {
    label: 'Gruppenavn',
    type: 'text',
    value: '',
    position: 1.5,
    helperText: 'Gruppe for å redusere antallet bilder til 2'
  },
  theme: {
    label: 'Tema',
    type: 'text',
    value: '',
    position: 2,
    helperText: 'Temaet for konkurransen',
    hideOn: [
      {
        field: 'typeId',
        value: 4
      }
    ]
  },
  themePoints: {
    label: 'Ekstrapoeng for tema-bilde',
    type: 'number',
    value: 0,
    position: 3,
    helperText: 'Antall poeng pr stemmer hvis bildet er et tema-bilde',
    showOn: [
      {
        field: 'categoryId',
        value: 1
      }
    ],
    hideOn: [
      {
        field: 'typeId',
        value: 4
      }
    ]
  },
  imageCount: {
    label: 'Maks antall bilder',
    type: 'number',
    value: 1,
    position: 4,
    required: 'Du må velge antall bilder som er lov å levere',
    helperText: 'Maks antall bilder som kan sendes inn',
    hideOn: [
      {
        field: 'typeId',
        value: 4
      }
    ]
  },
  showCount: {
    label: 'Vis antall vinnere',
    type: 'number',
    value: 6,
    position: 5,
    helperText: 'Antall bilder som vises med navn',
    showOn: [
      {
        field: 'categoryId',
        value: 1
      }
    ],
    hideOn: [
      {
        field: 'typeId',
        value: 4
      }
    ]
  },
  uploadDate: {
    label: 'Start for opplasting',
    type: 'datetime',
    value: Date.now(),
    position: 6,
    required: 'Du må fylle inn dato for når det er lov å laste opp',
    helperText: 'Når er det lov å starte med å laste opp bilder',
    hideOn: [
      {
        field: 'typeId',
        value: 4
      }
    ]
  },
  stopUploadDate: {
    label: 'Stopp for opplasting',
    type: 'datetime',
    value: Date.now(),
    position: 6.5,
    helperText: 'Når stoppes muligheten for å laste opp bilder',
    showOn: [
      {
        field: 'categoryId',
        value: 3
      },
      {
        field: 'categoryId',
        value: 2
      },
      {
        field: 'categoryId',
        value: 1
      }
    ],
    hideOn: [
      {
        field: 'typeId',
        value: 4
      }
    ]
  },
  voteStartDate: {
    label: 'Start for å stemme',
    type: 'datetime',
    value: Date.now(),
    position: 7,
    required: 'Du må fylle inn dato for når det er lov å stemme',
    helperText: 'Når er det lov å begynne å stemme på bilder. Merk, det blir ulovlig å laste opp bilder når man kan begynne å stemme, så sett denne datoen senere enn opplastingstid.',
    showOn: [
      {
        field: 'categoryId',
        value: 1
      }
    ]
  },
  voteStopDate: {
    label: 'Stopp for å stemme',
    type: 'datetime',
    value: Date.now(),
    position: 8,
    required: 'Du må fylle inn dato for når avstemming slutter',
    helperText: 'Når stopper tillatelsen til å stemme.',
    showOn: [
      {
        field: 'categoryId',
        value: 1
      }
    ]
  },
  resultDate: {
    label: 'Vis resultat',
    type: 'datetime',
    value: Date.now(),
    position: 9,
    required: 'Du må fylle inn når resultatet skal vises',
    helperText: 'Når vil resultatene være tilgjengelig for alle',
    showOn: [
      {
        field: 'categoryId',
        value: 1
      }
    ]
  },
  bestof: {
    label: 'Best av',
    type: 'votingselect',
    value: [],
    position: 10,
    showOn: [
      {
        field: 'typeId',
        value: 4
      }
    ]
  }
}

const SetupVoting = ({match, history}) => {
  const classes = useStyles();
  const [voteData, setVotingData] = useState(null);
  const [values, setValues] = useState(valueDef);
  const votingId = parseInt(match.params.votingId);
  const [allVotings, setAllVotings] = useState([]);

  const fetchVotingData = async () => {
    if (votingId !== 0) {
      try {
        await Fetch(API_BASE_URL + "/voting/" + votingId, 'GET', {}).then(result => {
          setVotingData(result.data);
        })
      } catch (error) {
        showError(error);
      }
    }
  };

  const fetchAllVotings = async () => {
    try {
      await Fetch(API_BASE_URL + "/voting/all", 'GET', {}).then(result => {
        setAllVotings(result.data);
      })
    } catch (error) {
      showError(error);
    }
  }

  let form = useInfoForm(values, setValues);

  useEffect(() => {
    fetchVotingData();
    fetchAllVotings();
  }, [votingId]);

  useEffect(() => {
    if (voteData) {
      let newValues = _.cloneDeep(valueDef);
      setFetchDataLazy({values: newValues, data: voteData});
      if ('bestof' in newValues && newValues.bestof.value.length>0) {
        newValues.bestof.value.forEach((v,i) => {
          newValues.bestof.value[i].value = v.bestof.id;
          newValues.bestof.value[i].label = v.bestof.title;
        })
      }
      newValues = form.calculateFields(newValues);
      setValues(newValues);
    }
  }, [voteData])


  const saveVoting = async () => {
    let data = {};
    for (let key of Object.keys(values)) {
      if (key==='bestof') {
        data[key] = values[key].value.map(v => {return {bestofId: v.value}});
      } else {
        data[key] = values[key].value;
      }
    }
    let type = votingId === 0 ? 'POST' : 'PUT';
    let path = votingId === 0 ? 'create' : 'update';
    if (votingId !== 0)
      data.id = votingId;
    await Fetch(API_BASE_URL + "/voting/" + path, type, data).then(result => {
      showInfo("Dataene ble lagret");
      setValues({...valueDef});
      history.push("/dashboard");
    }).catch(err => {
      showErrorText("Noe gikk galt ved lagring av data");
    })
  }

  return (
    <div id={"main"} className={"setup-voting"}>
      <div>
        <Container maxWidth={"md"}>
          <Box className={"box"}>
            <h3>Konkurranse</h3>
            <p className={"instruction"}>
              Fyll inn informasjonen om konkurransen
            </p>
            <form noValidate autoComplete="off">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <>
                  {FieldsToRows({values, classes, infoForm: form, data: {votings: allVotings}})}
                  <ButtonBlack className="mt-3" disabled={form.hasErrors()}
                               onClick={() => saveVoting()}>{votingId === 0 ? 'Opprett' : 'Lagre'}</ButtonBlack>
                </>
              </MuiPickersUtilsProvider>
            </form>
          </Box>
        </Container>
      </div>
    </div>
  )
}


const mapStateToProps = state => ({});

const connected = connect(mapStateToProps, {})(SetupVoting);

export default withRouter(connected);
