import {useEffect, useState} from 'react';

const _ = require('lodash');

const useForm = (initialState = {}, callback, validate) => {

  const [original, setOriginal] = useState({});
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [changed, setChanged] = useState({});

  useEffect(() => {
      if (!_.isEmpty(initialState) && !_.isEqual(initialState, original)) {
        setValues(initialState);
        setOriginal(initialState);
      }
      console.log("Doing initialState");
    },
    [initialState]
  );

  useEffect(() => {
    setErrors(validate(values));
    if (!_.isEqual(original, values)) {
      setIsChanged(true);
    } else {
      setIsChanged(false);
    }
    console.log("New values", values);
  }, [values]);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      setIsSubmitting(false);
      callback();
    }
  }, [errors]);

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    setIsSubmitting(true);
    setErrors(validate(values));
  };

  const handleKey = key => value => {
    handleKeyValue(key, value);
  };

  const handleChange = (event) => {
    event.persist();
    const key = event.target.name;
    const value = event.target.value;

    handleKeyValue(key, value);
  }

  const handleKeyValue = (key, value) => {
    if (!_.isEqual(original[key],value)) {
      setChanged({...changed, [key]: value});
    } else {
      let c = {...changed};
      delete c[key];
      setChanged(c);
    }
    setValues(values => ({...values, [key]: value}));
  };

  const switchArray = (key, value) => {
    if (values[key].some(v => v===value)) {
      handleKeyValue(key, values[key].filter(v => v !== value).sort());
    } else {
      handleKeyValue(key, [...values[key], value].sort());
    }
  }

  const doValidation = () => {
    setErrors(validate(values));
  }

  return {
    values,
    errors,
    isChanged,
    changed,
    handleChange,
    handleSubmit,
    handleKey,
    doValidation,
    switchArray
  }
};

export default useForm;
